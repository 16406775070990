.headline-wrapper{
	position: relative;
	.mask{
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
}

.event{
	position: relative;
	.top-info{
		position: absolute;
		top:0;
		bottom: 0;
		right: 0;
		left: 0;
		& > .row{
			height: 100%;
		}
	}
}

.h4{
	line-height: 35px;
}

@mixin set-flex-order($element, $divs){
  @for $i from 1 through $divs{
    #{$element}:nth-child(#{$i}){
      order: #{$i};
    }
  }
}

@mixin set-second-to-third($element){// put last name on second position

  @include media-breakpoint-down(sm) {
    #{$element}:nth-child(2){
      order:4;
    }
    #{$element}:nth-child(5){
      order:3;
    }
  }

}


.titles{
  background-color: $dk_white;
  display: inline-block;
  position:relative;
}

@mixin titleWrapper($direction: "bottom", $overflowed-space: 30px){

  position:relative;
  width:100%;
  margin-right:auto;
  margin-left:auto;

  @if $direction=="bottom" {

    margin-bottom: $overflowed-space;
    img{
      max-width: 95%;
    }

    .titleComponent{
      @extend .titles;
      margin: -20px 0px 0 15px;
      display: block;
      padding: 12px 20px 13px 20px;
    }

  } @else if $direction=="top" {

    overflow: hidden;

    @include media-breakpoint-up(sm) {
      margin-top: $overflowed-space;
    }

    .titleComponent{

      @extend .titles;
      padding: 11px 20px 18px;
      margin: 0 25px -35px;
    }
  }

}

$browser-context: 16; // Default

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

@function rem($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1rem;
}

.dateBoxes-wrapp{
	position: relative;
  .row{
    position:relative;
    &:after{
      position: absolute;
      left: 0;
      width: 107%;
      height: 30%;
      top:75%;
      @include media-breakpoint-up(md) {
        height: 70%;
        top:50%;
      }
      bottom: 0;
      content: '';
      background: $light_white;
      z-index: -1;
    }
  }
}

.text-black{
  color: $black;
}
.text-white{
  color: $white;
}

.box{
	line-height: 1.1;
	.lead{
		font-size: 1.55em;
	}
}

.eventDetails{

  .time{
    font-size: rem(25);
    line-height: rem(27);
  }
}

$form-fields : ".form-fields>div";

#ticket-modal{
  @include set-flex-order($form-fields, 8);
  @include set-second-to-third($form-fields);
}


.musicCast .cast-trigger{
  text-decoration: none;
  color: inherit;
}

.header.navbarContainer{
  position:relative;
  .navbar-header{

    @include media-breakpoint-down(sm) {

      .navbar-brand {
        img {
          width: 80px;
          margin-left: -39px;
        }
      }

      .navbar-toggler {
        padding-top:0;
        padding-bottom:0;

        i{
          width: 24px;
        }
      }
    }
  }

}

.navbarContainer{
  background-color: $white;

  nav{
    background-color: $white;
  }

  //height: 70px;
  min-height: 106px;
  @media screen and(max-width: 480px) {
    min-height: 60px;
  }

}

.navbar-collapse{

  .navbar-nav{
    flex-grow: 1;

    .nav-item{

      &.is-active>a{
        text-decoration: underline;
      }

      @include media-breakpoint-down(md) {
        text-align: center;
      }

      &:last-of-type{
        margin-right:0;
      }

    }

  }


  .navbar-brand{
    //margin: 0 70px;
    @include media-breakpoint-down(sm) {
      display:none;
    }
  }

}




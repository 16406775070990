.headline{
  .h1,h1,
  .h2,h2,
  .h3,h3,
  .h4,h4{
    margin-bottom: 0;
    line-height: 1;
  }
  .h1,h1{
    letter-spacing: 2px;
  }
  .h2,h2{
    letter-spacing: 1px;
  }
  @include media-breakpoint-down(sm) {

    .display-1 {
      font-size: rem(32);
    }

    .display-2 {
      font-size: rem(28);
    }

    .display-3 {
      font-size: rem(26);
    }
  }

}

small.custom-small{
  font-size:90%;
}

@include media-breakpoint-down(md) {

  .h1, h1{
    font-size: $font-size-base * 2.5;
  }

  .h2, h2{
    font-size: $font-size-base * 2.25;
  }

  .h3, h3{
    font-size: $font-size-base * 2.9375;
  }

  .h4, h4{
    font-size:$font-size-base * 1.5;
  }

  .h5, h5{
    font-size: $font-size-base * 1.25;
  }

  .h6, h6{
    font-size: $font-size-base;
  }

  .display-1 {
    font-size: rem(32);
  }

  .display-2 {
    font-size: rem(28);
  }

  .display-3 {
    font-size: rem(26);
  }
}

@include media-breakpoint-only(md) {
  .display-1 {
    font-size: rem(36);
  }

  .display-2 {
    font-size: rem(32);
  }

  .display-3 {
    font-size: rem(30);
  }
}

.profile-view {

  position: relative;
  padding-top: 152.2%;
  background-image: url(../images/no_avatar.png);
  background-repeat: no-repeat;
  background-color: #CCC;
  background-position: center;
  background-size: cover;
  color: $white;
  cursor: pointer;

  .profile-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    hr {
      width: 30px;
      border: 1px solid $light_gray;
    }
    h6 {
      font-weight: $font-weight-bold;
    }



  }

}


.profile-section{
  .profile-info {
    @include media-breakpoint-up(sm) {
      .info-column {
        padding: .5em 0;
      }
    }
  }
}

.profile-modal .profile-info.social-media{
  a{
    margin-right: rem(10);
    display: inline-block;
  }
}

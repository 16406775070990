.paragraph-group{

  @include media-breakpoint-up(sm) {
    .paragraph-el{
      &:first-of-type {
        padding-right: rem(46);
        border-right: 2px solid $dk_white;
      }
      &:last-of-type{
        padding-left: rem(46);
      }
    }
  }

}





.production-thumb{
	position: relative;

	.top-info{
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;

		.row{
			height: 100%;
		}
	}

	&:before{
		content: '';
		position: absolute;
    background-color: $dk_white;
		top: -20%;
		bottom: 20%;
		right: 0;
		left: 20%;
    z-index: -1;
	}

	&.row-reverse{
		&:before{
			right: 20%;
			left: 0;
		}
	}
}

$img-max-width: 506px;
.eventCard{
	background-color: $dk_white;

  .eventDescription{
    background-color: $light_gray;
    color: $white;
    h3,h4 {
      margin:0;
    }

    .dates{
      padding:.25em 0;
    }
  }


  .price{
    font-size: rem(37);
    @include media-breakpoint-down(md) {
      font-size: rem(30);
    }
  }

  @include media-breakpoint-up(md) {
    .eventframe{
      max-width: $img-max-width;
    }
  }

}

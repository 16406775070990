.overview{

  .overviewTitleContainer{
    @include titleWrapper("bottom", 20px);
  }
  .overviewContent{
    margin-left: 15px;
  }

}

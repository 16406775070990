$overflow: 10px;
$overflow-title: 25px;

.eventLocation{
  position: relative;

  .locationBox{
    max-width: 45%;
    display:inline-block;
    word-wrap: break-word;


    @include media-breakpoint-up(md) {
      min-width:35%;
    }

    @include media-breakpoint-only(md) {
      max-width: 50%;
    }
    @include media-breakpoint-down(sm) {
      width:100%;
      max-width: none;
    }



  }

  .overlay{
    padding-top: $overflow-title;
    @include media-breakpoint-up(sm) {
     position: absolute;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
     z-index: 1;
   }
   & > .row{
    height: 100%;
  }
}

.iframe-container{
  position:relative;
  padding-bottom: 53.80017079%;
  iframe{
    width:100%;
    height: 100%;
    position:absolute;
    top:0;
    bottom: 0;
    left:0;
    right:0;
    z-index: -1;
  }
}
.addresses{
  p{
    margin: 0;
  }
}
  .locationDetails{
    background-color: $dk_white;
    padding-left: 2px;

    .venue-name{
      margin-bottom: 0;
      line-height: 1;
    }

    .section{
      transform: translateY(-$overflow-title);
      .subtitle{
        margin-left:0.2em;
      }
    }

    .addresses{
      padding-top: 1em;
    }

  }

  a[href^=tel]{
    color:inherit;
    text-decoration: none;
  }
}

.footer {
  background-color: $brand-primary;
  font-weight: 300;
  overflow: hidden;

  a:hover{
    text-decoration: none;
  }

  .footer-info{
    a{
      color: $white;
    }

  }

  h5,.h5, h4, .h4{
    font-weight: 700;
  }

  .social-footer{
    font-size: $font-size-h4;
    a{
      color: $white;
      margin-right: rem(20);
      display: inline-block;
    }
  }

  p, .sections-footer a{
    color: $white;
  }

  .footer-copyright {
    margin: 30px auto 41px auto;
    font-size: $font-size-xs;
  }
  .sections-footer{

    margin-top: rem(30);
    text-transform: uppercase;
    font-weight: normal;


    @media (max-width: 480px){
      display:flex;
      flex-wrap: wrap;
      justify-content: center;

      &>a{
        width:50%;
      }
    }

  }

  @media (max-width: 480px){
    .btn{
      padding-left: 25px;
      padding-right: 25px;
    }
  }

}

.text {
  p > span:nth-of-type(2) {
    display: none;
  }

  &.show-text{
    p>span:nth-of-type(2){
      display:inline;
    }
    .d-none{
      display:block!important;
    }
  }
}


.spacer{
  padding: .25em;
}


.production-thumb, .overviewColumn{
  .buttons>.btn{
    padding-left: 25px;
    padding-right: 25px;
  }
}

#static-modal{
	display: block;

	&.modal-center{
		.modal-dialog{
			top:50%;
			transform: translateY(-50%);
		}
	}
}
#TestChrous{
	&.modal-center.show .modal-dialog{
		top:50%;
		transform: translateY(-50%);
	}
}

.profile-modal, #ticket-modal{
	.modal-dialog{
		@include media-breakpoint-up(sm) {
			width: 80%;
			max-width: 1361px;
		}
	}



	.table{
		margin-bottom: 0;
	}
}
.profile-modal{

	.modal-dialog{

		@include media-breakpoint-up(lg) {
			.modal-content{
				&:before{
					content: '';
					position: absolute;
					background-color: $dk_white;
					top: 0%;
					width:65%;
					height: 80%;
					right: 0;
				}
			}
		}
	}

	.profile-info{

		.withseparator{
			position:relative;
			&:after {
				content: '';
				background-color: $light_gray;
				//height: 2px;
				//width: 100%;
				////left: 0;
				//bottom: 4px;
				//display: block;
				//position: absolute;
				@include media-breakpoint-up(md) {

					height: 100%;
					width: 2px;
					right: 0;
					top: 50%;
					transform: translateY(-50%);

				}
			}

		}
	}
}

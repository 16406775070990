$light_gray: #414141;

$enable-rounded: false ;

$body-color: $light_gray;

$spacer: 5rem;

$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3)
);


$theme-colors: (
        primary: $white,
        secondary:  $light_gray,
        success: $green,
        info: $cyan,
        warning: $yellow,
        danger: $red,
        light: $gray-100,
        dark: $gray-800
) ;

$grid-gutter-width: 1em ;

$font-family-base:  "Lato", Arial, sans-serif;

$h1-font-size:                $font-size-base * 5.5;
$h2-font-size:                $font-size-base * 3.25;
$h3-font-size:                $font-size-base * 2.625;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;

$display1-size:       8.75rem;
$display2-size:       5.625rem;
$display3-size:       3.125rem;
$display4-size:       3.5rem;

$input-btn-padding-y:         .4rem !default;
$input-btn-padding-x:         .5rem !default;

$input-btn-padding-y-sm:      .5rem ;
$input-btn-padding-x-sm:      .5rem !default;

$input-btn-padding-y-lg:      .1rem !default;
$input-btn-padding-x-lg:      1rem !default;

$display1-weight:     400;
$display2-weight:     400;
$display3-weight:     400;
$display4-weight:     400;
$display-line-height: $headings-line-height;


$navbar-dark-color:                 $black;
$navbar-dark-hover-color:           $black;
$navbar-dark-active-color:          $black;
$navbar-dark-disabled-color:        $black;

$modal-content-border-color: transparent;

$modal-inner-padding:       0;
$modal-backdrop-bg:         #fff;
$modal-backdrop-opacity:    .8;

$carousel-control-prev-icon-bg: url("./../images/left_arrow@3x.svg");
$carousel-control-next-icon-bg: url("./../images/left_arrow@3x.svg");
$carousel-control-icon-width: 19px;

$link-color:                #8c8c8c;
//$link-decoration:           none !default;
$link-hover-color:          #8c8c8c;
//$link-hover-decoration:     underline !default;



$nav-tabs-link-hover-border-color:  $link-color;
$nav-tabs-link-active-color:        $link-color;
//$navbar-dark-color: $link-color;
$navbar-dark-hover-color: $link-color;
$navbar-dark-active-color:         $link-color;
//$nav-tabs-link-active-bg:           $body-bg !default;
//$nav-tabs-link-active-border-color: #ddd !default;

.places{

  .btn{
    min-width:165px;
  }

  .address,.description{
    //font-size: rem(25);
    font-size: 1.2rem;
  }

  .address .no-padding p{
    margin-bottom: 0;
  }

  a[href^=tel]{
    color:inherit;
    text-decoration: none;
  }
}
.placesContainer{
	.titleComponent{
		font-size: 2.5rem
	}
}


.copyBlog{
  .copyContent{
    font-size: rem(25);
  }


  .content{
    position:relative;
    &:after{
      content: '';
      display:block;
      background-color: $light_white;
      width:33.3%;
      height: 58.16%;
      z-index: -1;
      position:absolute;
      top:10%;
      left:0;

    }
    &.w-title:after{
      margin-top: 1.5rem;
      @include media-breakpoint-up(sm) {
        margin-top: 2.5rem; //like mt-2 class
      }
    }
  }
  & > .text-left{
    position: relative;
    padding-bottom: 2rem;
    &:before{
      content: '';
      display: block;
      width: 29%;
      @include media-breakpoint-up(sm) {
        width: 35%;
        top:17%
      }
      height: 40%;
      background: $light_white;
      position: absolute;
      top: 22%;
      left: 0;
    }
  }
}

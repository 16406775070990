//custom colors

$light_gray: #414141;
$light_white: #FAFAFA;
$dk_white: #F4F4F4;
$brand-primary: #000000;
$dark_green: #8B967A;

$font-size-base: 			1rem !default;
$font-size-lg:  			1.25rem !default;
$font-size-sm:   			.875rem !default;
$font-size-xs:   			.75rem !default;
$font-size-h4: 				1.563rem !default;

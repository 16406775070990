.slideContainer{
  position:relative;
}

.carousel-control-next-icon{
  transform: scaleX(-1);
}

@include media-breakpoint-up(sm) {
  .carousel-control-prev, .carousel-control-next {
    //top: 50%;
    //transform: translateY(-50%);
    position: relative;
    width: auto;
  }
}

@include media-breakpoint-up(sm) {
  .calendar-title {
    padding-left: .2em;
  }
}

@include media-breakpoint-down(sm) {
  .carousel-control-prev, .carousel-control-next{
    width: auto;
  }
}

.carousel-control-next-icon, .carousel-control-prev-icon{
  height: 72px;
}



.dayContainer{
  padding: 0.9375rem 0.625rem;

  background-color: $dk_white;
  a{
    color: $black;
  }
  .box{
    text-decoration: none;
    color: $black;
    margin-bottom: 0.625rem;
    min-height:85px;

    &:last-child{
      margin-bottom:0;
    }
  }
}

.carousel-showmanymoveone
{
  //$percent: 25%;

  //.carousel-inner .carousel-item-next, .carousel-inner .active.carousel-item-right {
  //  transform: translateX($percent);
  //  @supports (transform-style: preserve-3d) {
  //    transform: translate3d($percent, 0, 0);
  //  }
  //}

  //.carousel-inner .carousel-item-prev, .carousel-inner .active.carousel-item-left {
  //  transform: translateX(-$percent);
  //  @supports (transform-style: preserve-3d) {
  //    transform: translate3d(-$percent, 0, 0);
  //  }
  //}

  //.carousel-inner .carousel-item-right,
  //.carousel-inner .carousel-item-left{
  //  transform: translateX(0);
  //  @supports (transform-style: preserve-3d) {
  //    transform: translate3d(0, 0, 0);
  //  }
  //}



}
